// v 1.7.7
import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { Config, SERVER } from '../constants/url';

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: `${SERVER}/api`,
});

axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (e: any) => {
    return isNetworkOrIdempotentRequestError(e) && e.response?.status !== 500;
  },
});

const getRequest = async (URL: string, signal?: AbortSignal): Promise<any> =>
  await axiosInstance({
    method: 'get',
    url: URL,
    headers: Config.HEADERS,
    signal,
  });

const getRequestFile = async (
  URL: string,
  options: any,
  headers: any,
  signal?: AbortSignal
): Promise<any> =>
  await axiosInstance({
    method: 'get',
    url: URL,
    headers: { ...Config.HEADERS, ...headers },
    signal,
    ...options,
  });

const postRequest = async (
  URL: string,
  headers: any,
  data?: any,
  signal?: AbortSignal
): Promise<any> =>
  await axiosInstance({
    method: 'post',
    url: URL,
    headers: { ...Config.HEADERS, ...headers },
    data,
    signal,
  });

const putRequest = async (
  URL: string,
  headers?: any,
  data?: any,
  signal?: AbortSignal
): Promise<any> =>
  await axiosInstance({
    method: 'put',
    url: URL,
    headers: { ...Config.HEADERS, ...headers },
    data,
    signal,
  });

const deleteRequest = async (URL: string, signal?: AbortSignal): Promise<any> =>
  await axiosInstance({
    method: 'delete',
    url: URL,
    headers: Config.HEADERS,
    signal,
  });

export {
  axiosInstance,
  getRequest,
  getRequestFile,
  postRequest,
  putRequest,
  deleteRequest,
};
