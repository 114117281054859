export const Config = {
  HEADERS: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-XSS-Protection': '1; mode=block', // Enables XSS protection in browsers
    'X-Frame-Options': 'DENY', // Prevents the site from being loaded in iframes
  },
  MULTIPART_HEADERS: {
    'Content-Type': 'multipart/form-data; charset=utf-8; boundary=---sad',
    'X-XSS-Protection': '1; mode=block', // Add the same headers to multipart config if needed
    'X-Frame-Options': 'DENY',
  },
};

// export const SERVER =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:5000'
//     : `https://${process.env.DOMAIN}`;

export const SERVER = `https://chiesi-track.onpoint.ru`;