import { createContext, useState } from 'react';

type AuthContextType = {
  isAuthenticated: boolean;
  setAuth: (auth: boolean) => void;
  token: string;
  setToken: (token: string) => void;
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setAuth: () => {},
  token: '',
  setToken: () => {},
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [isAuthenticated, setAuth] = useState(false);
  const [token, setToken] = useState('');

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuth,token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
