import { useEffect, useState } from "react";
import fileDownload from "js-file-download";

import { getRequest, getRequestFile, postRequest } from "../utils/axios";
import useAuth from "../hooks/useAuth";
import { AxiosError } from "axios";

const Main = () => {
  const { token } = useAuth();
  const [emailId, setEmailId] = useState("");
  const [count, setCount] = useState("");
  const [image, setImage] = useState("");

  const getImage = async () => {
    if (emailId === "") {
      alert("email id не может быть пустым");
      return;
    }
    try {
      const result = await postRequest(
        `images`,
        { Authorization: `Bearer ${token}` },
        { emailId: emailId }
      );
      setImage(result.data);
    } catch (err) {
      console.log(err);
      if (err instanceof AxiosError && err.response?.status === 409) {
        try {
          const result = await getRequestFile(
            `images/link/${emailId}`,{},
            { Authorization: `Bearer ${token}` },
          );
          setImage(result.data);
        } catch (err) {
          console.log(err);
        }
        alert("Письмо с таким e-mail id уже существует. Ссылка для данного id будет отображена на странице");
      }
    }
  };

  const getExcel = async () => {
    if (emailId === "") {
      alert("email id не может быть пустым");
      return;
    }
    try {
      const result = await getRequestFile(
        `images/statistics/${emailId}`,
        { responseType: "blob" },
        { Authorization: `Bearer ${token}` }
      );

      if (result) {
        const filename = result.headers["content-disposition"]
          .split("filename=")[1]
          .split(".")[0]
          .replaceAll('"', "");
        console.log(filename);
        const extension = result.headers["content-disposition"]
          .split(".")[1]
          .split(";")[0]
          .replaceAll('"', "");

        await fileDownload(result.data, `${filename}.${extension}`);
      }
    } catch (err) {
      console.log(err);
      if (err instanceof AxiosError && err.response?.status === 404) {
        alert("Письмо с таким e-mail id не найдено");
      }
    }
  };

  const getCount = async () => {
    if (emailId === "") {
      alert("email id не может быть пустым");
      return;
    }
    try {
      const result = await getRequestFile(
        `images/openingCounter/${emailId}`,
        {},
        { Authorization: `Bearer ${token}` }
      );
      setCount(result.data);
    } catch (err) {
      console.log(err);
      if (err instanceof AxiosError && err.response?.status === 404) {
        alert("Письмо с таким e-mail id не найдено");
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmailId(value);
  };

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 600000);
  });

  return (
    <div className="main">
      <h1>Статистика</h1>
      <div className="block-wrapper">
        <form>
          <div className="input-wrapper">
            <label>
              E-mail id:
              <input
                type="text"
                name="emailId"
                value={emailId}
                onChange={handleInputChange}
              />
            </label>
          </div>
        </form>
      </div>
      <div className="block-wrapper">
        <label>Ссылка на изображение:</label>
        <label>{image}</label>
        <button type="button" onClick={getImage}>
          Создать ссылку на изображение
        </button>
      </div>

      <div className="block-wrapper">
        <label>Количество открытий: {count}</label>
        <button type="button" onClick={getCount}>
          Получить количество открытий
        </button>
        <button type="button" onClick={getExcel}>
          Получить файл
        </button>
      </div>
    </div>
  );
};

export default Main;
