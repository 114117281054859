import { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { postRequest } from '../utils/axios';

interface FormData {
  username: string;
  password: string;
  code: string;
}

const Login = () => {
  const { setAuth, setToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [formData, setFormData] = useState<FormData>({
    username: '',
    password: '',
    code: '',
  });

  const sendLogin = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      return await postRequest(
        'auth/login',
        {},
        {
          username,
          password,
        }
      );
    } catch (err) {
      console.log(err);
      alert('Неверный логин или пароль');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const getSecondStep = async () => {
    const result = await sendLogin({
      username: formData.username,
      password: formData.password,
    });

    if (result?.status !== 201) return;
    
    setAuth(true);
    setToken(result?.data?.access_token);
    navigate(from, { replace: true });
  };

  return (
    <div className="login-wrapper">
      <h1 className="login-title">Авторизация</h1>
      <form>
        <div className="input-wrapper">
          <label>
            Логин:
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  getSecondStep();
                }
              }}
            />
          </label>
        </div>
        <br />
        <div className="input-wrapper">
          <label>
            Пароль:
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  getSecondStep();
                }
              }}
            />
          </label>
        </div>
        <br />
      </form>
      <button onClick={getSecondStep}>Войти</button>
    </div>
  );
};

export default Login;
